import { Item } from '@wix/ambassador-portfolio-project-items-v1-project-item/types';

export const commonImagesToWixCode = (items: Item[]) => {
  const wixCodeItems = items?.map((item?: Item) => {
    if (item?.image) {
      const imageInfo = item?.image?.imageInfo;
      return {
        type: 'image',
        title: item?.title || imageInfo?.altText,
        description: item?.description || '',
        alt: imageInfo?.altText || item?.title,
        // @ts-expect-error
        projectSlug: item.projectSlug,
        // @ts-expect-error
        collectionSlug: item.collectionSlug,
        imageToken: item?.image?.token,
        src: `${imageInfo?.url}#originWidth=${imageInfo?.width}&originHeight=${imageInfo?.height}`,
        settings: item.image.focalPoint
          ? { focalPoint: [item.image.focalPoint.x, item.image.focalPoint.y] }
          : {},
      };
    } else {
      const videoInfo = item?.video?.videoInfo;
      const videoPoster = videoInfo?.posters && videoInfo?.posters[0];
      return {
        type: 'video',
        title: item?.title || videoInfo?.filename,
        description: item?.description || '',
        imageToken: '',
        // @ts-expect-error
        projectSlug: item.projectSlug,
        // @ts-expect-error1
        collectionSlug: item.collectionSlug,
        src: `wix:video://v1/${videoInfo?.filename}/_#posterUri=${videoPoster?.id}&posterWidth=${videoPoster?.width}&posterHeight=${videoPoster?.height}`,
      };
    }
  });
  return wixCodeItems;
};
